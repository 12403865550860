import React, { useEffect, useState } from "react";
import white_icon from "../images/white-icon.png";
import coloured_icon from "../images/colored-icon.png";
import { Link } from "react-router-dom";

import robotic from "../img/trainings/cover_image/robotics.jpeg";
import iot from "../img/trainings/cover_image/iot.jpeg";
import frontend from "../img/trainings/cover_image/frontend.jpeg";
import backend from "../img/trainings/cover_image/backend.jpeg";
import devops from "../img/trainings/cover_image/devops.jpeg";
import cad from "../img/trainings/cover_image/3dcad.jpeg";

import scifi from "../images/scifi.jpg";

const Header = () => {
  const urlPath = window.location.pathname;

  const [isNavOpen, setIsNavOpen] = useState(false);

  const expand = () => {
    setIsNavOpen(true);
    showNavModalEffect();
    hideNavbar();
  };

  const collapse = () => {
    showNavbar();
    hideNavModal();
    setIsNavOpen(false);
  };

  const handleClickOutside = (e) => {
    if (e.target.id === "navModal") {
      collapse();
    }
  };

  const showNavModalEffect = () => {
    const modalContent = document.querySelector(".modal__content");
    modalContent.classList.add("slide__left");
    setTimeout(() => {
      modalContent.classList.remove("slide__left");
    }, 1000);
  };

  const hideNavModal = () => {
    const modalContent = document.querySelector(".modal__content");
    modalContent.classList.add("lessen__modal");
    setTimeout(() => {
      modalContent.classList.remove("lessen__modal");
    }, 1000);
  };

  const hideNavbar = () => {
    const navbar = document.querySelector(".navbar");
    navbar.style.display = "none";
  };

  const showNavbar = () => {
    const navbar = document.querySelector(".navbar");
    navbar.style.display = "block";
  };

  const programs = [
    {
      id: 0,
      title: "Robotics Engineering",
      description:
        "Our Robotics Engineering Training program provides a comprehensive exploration into the captivating realm of robotics, covering both hardware and software aspects. Participants will delve into fundamental concepts while gaining hands-on experience through engaging projects.",
      image: robotic,
      url: "robotics-engineering",
    },
    {
      id: 1,
      title: "Internet of Things (IoT)",
      description:
        "Our Internet of Things (IoT) Training program provides a comprehensive journey into the interconnected world of smart devices, encompassing both theoretical understanding and hands-on experience. Participants will explore the fundamental principles of IoT architecture, ecosystems, and key technologies through engaging projects.",
      image: iot,
      url: "iot",
    },
    {
      id: 2,
      title: "Frontend Web Development",
      description:
        "Our Frontend Web Development Training program is designed to equip you with both the foundational skills and advanced techniques necessary for creating responsive and interactive websites.",
      image: frontend,
      url: "frontend-web-development",
    },
    {
      id: 3,
      title: "Backend Web Development",
      description:
        "Our Backend Web Development Training program delves into the intricacies of server-side development, focusing on essential concepts and tools required to build robust and scalable web applications. Participants will gain proficiency in server setup, database management, and backend frameworks, empowering them to craft efficient and secure server-side logic for modern web services.",
      image: backend,
      url: "backend-web-development",
    },
    {
      id: 4,
      title: "Devops Engineeering",
      description:
        "Our DevOps Engineering Training program delves into the dynamic intersection of software development and IT operations, providing comprehensive coverage of essential DevOps practices and tools. Participants will gain practical skills in automation, continuous integration and delivery (CI/CD), infrastructure as code (IaC), and monitoring, empowering them to streamline software delivery pipelines and enhance collaboration across development and operations teams.",
      image: devops,
      url: "devops-engineering",
    },
    {
      id: 5,
      title: "3D CAD Modeling",
      description:
        "Our 3D CAD Modeling Training program immerses participants in the exciting world of computer-aided design, covering essential concepts and tools necessary for creating precise and intricate 3D models. With a focus on popular CAD software such as AutoCAD, SolidWorks, and Fusion 360, participants will develop the skills needed to bring their design ideas to life in various industries including manufacturing, engineering, and product design.",
      image: cad,
      url: "3d-cad-modeling",
    },
  ];

  useEffect(() => {
    let index = 0;
    let scrollPosition = 0;

    const shift = (callback) => {
      document.querySelector(".static__logo").classList.add("hidden");
      document.querySelector(".fixed__logo").classList.remove("hidden");
      document.querySelector(".navbar").classList.remove("static");
      document.querySelector(".navbar").classList.add("shift");
      callback();
    };

    const handleScroll = () => {
      scrollPosition = window.scrollY;
      let navbar = document.querySelector(".navbar");
      let defaultnav = document.querySelector(".defaultnav");

      if (scrollPosition > 0) {
        if (urlPath === "/") {
          defaultnav.classList.remove("nav-container");
          defaultnav.classList.add("white-nav-container");
        }

        if (!navbar.classList.contains("fixed")) {
          navbar.classList.add("fixed");
          navbar.classList.add("slide-down");
          navbar.classList.remove("slide-up");
        }

        if (
          !document.querySelector(".static__logo").classList.contains("hidden")
        ) {
          document.querySelector(".static__logo").classList.add("hidden");
        }
        if (
          document.querySelector(".fixed__logo").classList.contains("hidden")
        ) {
          document.querySelector(".fixed__logo").classList.remove("hidden");
        }
      } else {
        if (urlPath === "/") {
          defaultnav.classList.add("nav-container");
          defaultnav.classList.remove("white-nav-container");
        }

        if (navbar.classList.contains("fixed")) {
          navbar.classList.remove("fixed");
          navbar.classList.add("slide-up");
          navbar.classList.remove("slide-down");
        }

        document.querySelector(".static__logo").classList.remove("hidden");
        document.querySelector(".fixed__logo").classList.add("hidden");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function checkUrlPattern(url) {
    const pattern = /^\/blog\/[\w-]+\/post\/[\w-]+$/;
    return pattern.test(url);
  }

  return (
    <div>
      <div
        class={`
                defaultnav ${
                  urlPath === "/" || urlPath === "/trainings"
                    ? "nav-container"
                    : urlPath === "/about-us" ||
                        checkUrlPattern(urlPath) ||
                        urlPath.includes("/trainings/")
                      ? "page__banner nav-container"
                      : "white-nav-container"
                }`}
        style={{
          backgroundImage: checkUrlPattern(urlPath)
            ? `linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.50)), url(${window.localStorage.getItem("post_banner")})`
            : urlPath.includes("/trainings/")
              ? `linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.50)), url(${programs[window.localStorage.getItem("training_id")].image})`
              : urlPath === "/about-us"
                ? `linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.50)), url(${scifi})`
                : "",
          backgroundSize: "200% 100%",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
        }}
      >
        <nav class="navbar">
          <div class="logo static__logo">
            <a href="/">
              <img
                src={
                  urlPath === "/" ||
                  urlPath === "/trainings" ||
                  checkUrlPattern(urlPath)
                    ? white_icon
                    : urlPath === "/about-us" || urlPath.includes("/trainings/")
                      ? white_icon
                      : coloured_icon
                }
              />
            </a>
            <div class="mobile__menu" onClick={expand} id="toggler">
              <i
                style={{
                  color:
                    urlPath === "/" ||
                    urlPath.includes("/trainings") ||
                    urlPath === "/about-us" ||
                    checkUrlPattern(urlPath)
                      ? "white"
                      : "var(--blue)",
                }}
                class="bx bx-menu"
              ></i>
            </div>
          </div>

          <div class="logo fixed__logo hidden">
            <a href="/">
              <img src={coloured_icon} />
            </a>
            <div class="mobile__menu" onClick={expand}>
              <i class="bx bx-menu"></i>
            </div>
          </div>

          <ul id="link__list">
            <li>
              <Link to="/sw-services">Software Services</Link>
            </li>
            <li>
              <Link to="/services">Hardware Services</Link>
            </li>
            <li>
              <Link to="/events">Event</Link>
            </li>
            <li>
              <Link to="/trainings">Trainings</Link>
            </li>
            <li>
              <Link to="/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>

            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </nav>
        {urlPath === "/about-us" ? (
          <div class="banner__content">
            <div class="inner__content">
              <h4>The home of technological innovation</h4>
              <p>
                {" "}
                From the East, West, North and South of the Universe, we welcome
                challenges needing quick technological interventions.{" "}
                <em>
                  “Innovating varieties of solutional approaches to needs and
                  problems via Automation”{" "}
                </em>{" "}
                is our Mantra.
              </p>
            </div>
          </div>
        ) : urlPath.includes("/trainings/") ? (
          <div class="banner__content training_banner">
            <div class="inner__content">
              <div className="brief">
                <i class="bx bxs-right-arrow"></i>
                <p>ONE MONTH INTENSIVE TRAINING ON</p>
                <i class="bx bxs-left-arrow"></i>
              </div>
              <h1>
                {programs[
                  window.localStorage.getItem("training_id")
                ].title.toUpperCase()}
              </h1>
              <p>
                {
                  programs[window.localStorage.getItem("training_id")]
                    .description
                }
              </p>
              <br />
              <div>
                <Link to="/event/register">
                  <button class="button__red">Register</button>
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div
        className="nav__modal"
        id="navModal"
        data-visible="not-visible"
        style={{ display: `${isNavOpen ? "block" : "none"}` }}
      >
        <div className="inner__content">
          <div className="modal__content">
            <div className="modal__logo">
              <Link to="/">
                <img src={coloured_icon} alt="Company logo" />
              </Link>
            </div>
            <div className="links__container">
              <div className="link">
                <a href="/sw-services">Software Services</a>
              </div>
              <div className="link">
                <Link to="/services">Hardware Services</Link>
              </div>
              <div className="link">
                <Link to="/events">Event</Link>
              </div>
              <div className="link">
                <Link to="/trainings">Trainings</Link>
              </div>
              <div className="link">
                <Link to="/about-us">About Us</Link>
              </div>
              <div className="link">
                <Link to="/blog">Blog</Link>
              </div>
              <div className="link">
                <Link to="/contact-us">Contact Us</Link>
              </div>
            </div>
          </div>
          <div className="close__nav" onClick={collapse} id="closeNav">
            &times;
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
