import React, { useEffect } from "react";
import eventImg from "../images/event.jpeg";
import eventImg2 from "../images/automachoice-flyer.jpeg";
const Event = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <section className="event-section">
        <div className="event-category">
          <h3>Upcoming Events</h3>
        </div>
        <div className="event-container">
          <div className="event-image">
            <img src={eventImg} alt="" srcset="" />
          </div>
          <div className="event-description">
            <h1 className="header">
              Unlock Tech Mastery & Transform Your Career Path! Enroll Now
            </h1>
            <div className="bottom__border"></div>
            <p className="content">
              Are you ready to embark on an exciting journey into the world of
              technology? Don't miss out on our upcoming physical training
              programs designed to equip you with the skills and knowledge
              needed to thrive in today's digital age.
              <br />
              Don't miss this opportunity to level up your skills and stay ahead
              in today's tech-driven world. Enroll now and take the first step
              towards an exciting career in technology!
              <br />
              <strong>VENUE:</strong> Automachoice Systems Training Centre,
              Itamaga, Ikorodu, Lagos.
              <br />
              <strong>DURATION:</strong> One Month
              <br />
              <strong>START DATE:</strong> August 5th, 2024.
              <br />
              <strong>FEE:</strong> NGN100,000 only.
            </p>
            <p>
              For more details on the programs, please click{" "}
              <a href="/trainings">here</a>
            </p>
            <br />
          </div>
        </div>
        <div className="event-category">
          <h3>Past Events</h3>
        </div>
        <div className="event-container">
          <div className="event-image">
            <img src={eventImg2} alt="" srcset="" />
          </div>
          <div className="event-description">
            <h1 className="header">2024 Coding and Robotics Competition</h1>
            <div className="bottom__border"></div>
            <p className="content">
              We hereby extend invitation to Parents/Guardians and Academic
              Institutions to register your Kids for the 2024 Coding & Robotics
              Competition.
              <br />
              Group registrations and Individual registrations are welcome.
              <br />
              <strong>VENUE:</strong> UPBEAT RECREATION CENTRE, Lekki, Lagos.
              <br />
              <strong>DATE:</strong> April 6th, 2024.
              <br />
              <strong>FEE:</strong> NGN10,000 only.
            </p>
            <p>
              For more details on the programs, please click{" "}
              <a href="/blog/2024-Coding-and-Robotics-Competition/post/">
                here
              </a>
            </p>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Event;
