import React, { createContext, useContext, useState, useEffect } from "react";

const BlogContext = createContext();

export const useBlogContext = () => useContext(BlogContext);

export const BlogProvider = ({ children }) => {
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/mainblog/`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            ApiAuthorization: process.env.REACT_APP_API_KEY,
          },
        });
        const data = await response.json();
        setBlog(data);
      } catch (error) {}
    };

    if (blog.length === 0) {
      fetchBlog();
    }
  }, [blog]);

  return <BlogContext.Provider value={blog}>{children}</BlogContext.Provider>;
};

export default BlogProvider;
