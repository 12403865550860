import React, { useState, useEffect } from "react";
import formatDate from "../components/FormatDate";
import Loading from "../components/Loading";

const Post = () => {
  const blog_id = window.localStorage.getItem("blog_id");
  const [likeStatus, setLikeStatus] = useState(false);
  const [post, setPost] = useState({});
  const [blogComment, setBlogComment] = useState([]);

  const isPostNotEmpty = Object.keys(post).length > 0;
  const visitor_id = window.localStorage.getItem("visitor_id");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const fetchPost = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/blogs/${blog_id}`;

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          blogid: blog_id,
          ApiAuthorization: process.env.REACT_APP_API_KEY,
        },
      });
      const url_2 = `${process.env.REACT_APP_BASE_URL}/visitor/?blog_id=${blog_id}&visitor_id=${visitor_id}`;
      let response_2 = await fetch(url_2, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          ApiAuthorization: process.env.REACT_APP_API_KEY,
        },
      });
      response_2 = await response_2.json();
      let like_status = response_2["like_status"];
      setLikeStatus(like_status);

      const data = await response.json();
      window.localStorage.setItem("post_banner", data["image"]);
      setPost(data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchPost();
    getComments();
  }, []);

  const likeFunction = async (id) => {
    const url_3 = `${process.env.REACT_APP_BASE_URL}/visitor/`;
    let response = await fetch(url_3, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ApiAuthorization: process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify({
        blog_id: id,
        visitor_id: visitor_id,
      }),
    });
    response = await response.json();
    let like_status = response["like_status"];
    fetchPost();
  };

  const postComment = async (event) => {
    const url_4 = `${process.env.REACT_APP_BASE_URL}/comments/`;
    let response = await fetch(url_4, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ApiAuthorization: process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify({
        blog: blog_id,
        name: name,
        email: email,
        comment: comment,
      }),
    });
    response = await response.json();
    setComment("");
  };

  const getComments = async () => {
    const url_5 = `${process.env.REACT_APP_BASE_URL}/comments/?blog_id=${blog_id}`;
    let response = await fetch(url_5, {
      headers: {
        "Content-Type": "application/json",
        ApiAuthorization: process.env.REACT_APP_API_KEY,
      },
    });
    response = await response.json();
    setBlogComment(response);
  };

  return (
    <div>
      {isPostNotEmpty ? (
        <section className="blog__detail">
          <div className="blog__detail__banner">
            <img src={post.image} />
            <div className="reaction">
              <button
                className={`${likeStatus ? "liked" : "notliked"}`}
                onClick={() => likeFunction(post.id)}
              >
                <p>
                  <span id="like_id">{post.likes}</span> Likes
                </p>
              </button>
              <button className="comment">
                <p>
                  <span id="comment_id">
                    {blogComment.length > 0 ? blogComment.length : 0}
                  </span>{" "}
                  Comments
                </p>
              </button>
              <button className="share">
                <p>
                  <span id="share_id">0</span> Shares
                </p>
              </button>
            </div>
          </div>
          <div className="inner">
            <div className="header" id="header_id">
              {post.title}
            </div>
            <div className="bottom__border"></div>
            <p className="content" id="content_id">
              {post.description}
            </p>
          </div>
        </section>
      ) : (
        <Loading />
      )}
      <section className="share_link" id="share_link_id">
        <h4>Share via . . .</h4>
        <div className="social__media__links">
          <a href="#">
            <i className="bx bxl-facebook-circle"></i>
          </a>
          <a href="#" onclick="shareTwitter()">
            <i className="bx bxl-twitter"></i>
          </a>
          <a href="">
            <i className="bx bxl-instagram"></i>
          </a>
          <a href="">
            <i className="bx bxl-linkedin"></i>
          </a>
          <a href="">
            <i className="bx bxl-whatsapp"></i>
          </a>
        </div>
      </section>

      <section className="robo-section">
        <div className="dash"></div>
        <i className="fa-solid fa-robot"></i>
        <div className="dash"></div>
      </section>

      <section className="comments" id="comment__id">
        <p className="title">COMMENT</p>
        {blogComment.length > 0 ? (
          blogComment.map((element) => (
            <div className="comment-box">
              <div className="aside">
                <img
                  src="https://static.vecteezy.com/system/resources/previews/010/054/157/original/chat-bot-robot-avatar-in-circle-round-shape-isolated-on-white-background-stock-illustration-ai-technology-futuristic-helper-communication-conversation-concept-in-flat-style-vector.jpg"
                  alt=""
                />
                <div className="comment-line"></div>
              </div>
              <div className="main">
                <h4 className="username">{element.name}</h4>
                <p className="content">{element.comment}</p>
                <p className="datetime">{formatDate(element.created_at)}</p>
              </div>
            </div>
          ))
        ) : (
          <Loading />
        )}
      </section>
      <section className="comment__section">
        <h3>Comment here . . .</h3>
        <div className="comment__form">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            required
          />
          <input
            type="email"
            id="comment_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
            required
          />
          <textarea
            id="comment_message"
            name="message"
            rows="5"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Comment"
            required
          ></textarea>
          <button type="submit" className="button__red" onClick={postComment}>
            COMMENT
          </button>
        </div>
      </section>
    </div>
  );
};

export default Post;
